// @mui
import { Theme, SxProps } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TableRow from '@mui/material/TableRow';
// import Checkbox from '@mui/material/Checkbox';
import MuiTableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';

// ----------------------------------------------------------------------

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  clip: 'rect(0 0 0 0)',
} as const;

// ----------------------------------------------------------------------

type Props = {
  order?: 'asc' | 'desc';
  orderBy?: string;
  headLabel: any[];
  onSort?: (id: string) => void;
  sx?: SxProps<Theme>;
};

export default function TableHead({ order, orderBy, headLabel, onSort, sx }: Props) {
  return (
    <MuiTableHead
      sx={{
        position: 'sticky',
        top: 0,
        zIndex: 1000,
        ...sx,
      }}
    >
      <TableRow>
        {headLabel.map((headCell) => (
          <TableCell
            key={headCell.field}
            align={headCell.align || 'left'}
            sortDirection={orderBy === headCell.field ? order : false}
            sx={{
              width: headCell.width,
              minWidth: headCell.minWidth,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontWeight: 'bold',
            }}
          >
            {onSort ? (
              <TableSortLabel
                hideSortIcon
                active={orderBy === headCell.field}
                direction={orderBy === headCell.field ? order : 'asc'}
                onClick={() => onSort(headCell.field)}
              >
                {headCell.title}

                {orderBy === headCell.field ? (
                  <Box sx={{ ...visuallyHidden }}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.title
            )}
          </TableCell>
        ))}
      </TableRow>
    </MuiTableHead>
  );
}
