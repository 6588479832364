import { Children } from 'react';
// @mui
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Box } from '@mui/material';

import { Link } from 'react-router-dom';
// routes
import { paths } from 'src/routes/paths';

// types
import { ITableColumns } from 'src/types';

// utils
import { IUnpaidOrderItem } from 'src/types/unpaid-orders';

// ----------------------------------------------------------------------

type Props = {
  row: IUnpaidOrderItem;
  headLabel: Partial<ITableColumns>[];
};

export default function UnpaidOrdersTableRow({ row, headLabel }: Props) {
  return (
    <TableRow hover>
      {Children.toArray(
        headLabel.map((headCell) => {
          if (headCell?.field_mod) {
            return (
              <TableCell
                sx={{
                  whiteSpace: 'nowrap',
                  width: headCell.width,
                  minWidth: headCell.minWidth,
                }}
                align={headCell.align || 'left'}
              >
                {/* @ts-ignore */}
                {row?.[headCell?.field_mod]}
              </TableCell>
            );
          }
          if (headCell?.field) {
            if (headCell.field === 'order_code') {
              return (
                <TableCell
                  sx={{
                    width: headCell.width,
                    minWidth: headCell.minWidth,
                    whiteSpace: 'nowrap',
                    textDecoration: 'none',
                    color: 'black',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                  align={headCell.align || 'left'}
                >
                  <Box
                    component="span"
                    sx={{
                      '& a': {
                        textDecoration: 'none',
                        color: 'black',
                        '&:hover': {
                          textDecoration: 'underline',
                        },
                      },
                    }}
                  >
                    <Link to={`${paths.admin.orderTypes.clientOrders.details(row?.order_code)}`}>
                      {/* @ts-ignore */}
                      {row?.[headCell?.field]}
                    </Link>
                  </Box>
                </TableCell>
              );
            }
            if (headCell.field === 'client_name') {
              return (
                <TableCell
                  sx={{
                    width: headCell.width,
                    minWidth: headCell.minWidth,
                    whiteSpace: 'nowrap',
                    textDecoration: 'none',
                    color: 'black',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                  align={headCell.align || 'left'}
                >
                  <Box
                    component="span"
                    sx={{
                      '& a': {
                        textDecoration: 'none',
                        color: 'black',
                        '&:hover': {
                          textDecoration: 'underline',
                        },
                      },
                    }}
                  >
                    <Link to={`${paths.admin.clients.details(row?.client_slug)}`}>
                      {/* @ts-ignore */}
                      {row?.[headCell?.field]}
                    </Link>
                  </Box>
                </TableCell>
              );
            }
            return (
              <TableCell
                sx={{ whiteSpace: 'nowrap', width: headCell.width, minWidth: headCell.minWidth }}
                align={headCell.align || 'left'}
              >
                {/* @ts-ignore */}
                {row?.[headCell?.field]}
              </TableCell>
            );
          }
          return null;
        }),
      )}
    </TableRow>
  );
}
