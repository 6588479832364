import { Dispatch, SetStateAction, useMemo } from 'react';

// utils
import { useLocales } from 'src/locales';
import { createYears } from 'src/utils/formatting-years-array';
import { useBoolean } from 'src/hooks/use-boolean';

// mui
import {
  Button,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Typography,
  Box,
  Tooltip,
  Badge,
} from '@mui/material';
// types
import { IUnpaidOrderTableFilters } from 'src/types/unpaid-orders';
// components
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import CustomSelectField from './custom-select-field';

//----------------------------------------------------------------------------------
interface ClientAllFiltersProps {
  filters: IUnpaidOrderTableFilters;
  setFilters: Dispatch<SetStateAction<IUnpaidOrderTableFilters>>;
  canReset: boolean;
  handleResetAllFilters: () => void;
}
//-------------------------------------------------------------------------------------

export default function UnpaidOrderTableFilters({
  filters,
  setFilters,

  canReset,

  handleResetAllFilters,
}: ClientAllFiltersProps) {
  const { t } = useLocales();

  const filterSideBar = useBoolean();

  const { year, month } = filters;

  // creating years
  const years = useMemo(
    () =>
      createYears(false, true).map((val) =>
        val === 'all' ? { label: val, value: 'all' } : { label: val, value: val },
      ),
    [],
  );
  // months
  const months = useMemo(
    () =>
      Array(12 + 1)
        .fill('month')
        .map((key, index) =>
          index === 0 ? { label: 'all', value: 'all' } : { label: `${index}`, value: `${index}` },
        ),
    [],
  );

  const renderHead = (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ py: 2, pr: 1, pl: 2.5 }}
    >
      <Typography variant="h6" sx={{ flexGrow: 1 }}>
        {t('filters')}
      </Typography>

      <Tooltip title={t('reset')}>
        <IconButton onClick={handleResetAllFilters}>
          <Badge color="error" variant="dot" invisible={!canReset}>
            <Iconify icon="solar:restart-bold" />
          </Badge>
        </IconButton>
      </Tooltip>

      <IconButton onClick={filterSideBar.onFalse}>
        <Iconify icon="mingcute:close-line" />
      </IconButton>
    </Stack>
  );

  return (
    <Box
      display="flex"
      justifyContent={{ xs: 'center', md: 'end' }}
      alignItems="center"
      gap={{ xs: 1, sm: 4 }}
      p={1}
    >
      <Button
        disableRipple
        color="inherit"
        endIcon={<Iconify icon="ic:round-filter-list" />}
        onClick={filterSideBar.onTrue}
      >
        {t('filters')}
      </Button>
      <Drawer
        anchor="right"
        open={filterSideBar.value}
        onClose={filterSideBar.onFalse}
        slotProps={{
          backdrop: { invisible: true },
        }}
        PaperProps={{
          sx: { width: 280 },
        }}
      >
        {renderHead}

        <Divider />

        <Scrollbar sx={{ px: 2.5, py: 3 }}>
          <Stack spacing={3}>
            {/* years selector */}
            <CustomSelectField
              optionsArray={years}
              labelText={t('years')}
              value={year || 'all'}
              onChange={(obj) =>
                obj
                  ? setFilters((prev) => ({
                      ...prev,
                      year: obj.value === 'all' ? '' : obj.value,
                      month: '',
                    }))
                  : setFilters((prev) => ({
                      ...prev,
                      year: new Date().getFullYear().toString(),
                    }))
              }
            />
            {/* months */}
            <CustomSelectField
              optionsArray={months}
              labelText={t('months')}
              value={month || 'all'}
              onChange={(obj) =>
                obj
                  ? setFilters((prev) => ({
                      ...prev,
                      month: obj.value === 'all' ? '' : obj.value,
                    }))
                  : setFilters((prev) => ({
                      ...prev,
                      month: '',
                    }))
              }
              isDisabled={['last_12_months', 'previous_12_months'].includes(year?.toString() || '')}
            />
          </Stack>
        </Scrollbar>
      </Drawer>
    </Box>
  );
}
