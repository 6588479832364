import { useLocales } from 'src/locales';
// mui
import { Autocomplete, Stack, TextField, Typography } from '@mui/material';

import { convertToTitleCase } from 'src/utils/misc';

interface ICustomSelectFieldProps {
  optionsArray: { value: string; label: string }[];
  labelText: string;
  value?: any;
  onChange: (newValue: any) => void;
  isLoading?: boolean;
  placeholder?: string;
  isDisabled?: boolean;
}

export default function CustomReservationSelectField({
  optionsArray,
  labelText,
  value,
  onChange,
  isLoading = false,
  isDisabled,
}: ICustomSelectFieldProps) {
  const { t } = useLocales();
  return (
    <Stack>
      <Typography variant="subtitle2" sx={{ mb: 1.5 }}>
        {t(labelText)}
      </Typography>
      <Autocomplete
        loading={isLoading}
        loadingText={t('loading')}
        options={optionsArray}
        getOptionLabel={(option) => convertToTitleCase(t(option.label))}
        value={optionsArray.find((option) => option.value === value)}
        onChange={(event, newValue) => onChange(newValue)}
        renderInput={(params) => <TextField {...params} />}
        disabled={isDisabled}
      />
    </Stack>
  );
}
