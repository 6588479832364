// utils
// axiosInstance
import axiosInstance, { endpoints, endpointsLegacy } from 'src/utils/axios';
// types
import {
  IListFilters,
  APIListResponse,
  IFetchBaseParams,
  IClientRegions,
  IClientRegionsAndProvinces,
  IClientTableFilters,
  IClientItem,
  IReservationItem,
  IUserItem,
  IClientReferralItem,
  IClientReferralsTableFilters,
  IUserTableFilters,
  IReservationsTableFilters,
  IProductTableFilters,
  IOrderProductItem,
  IProducerTableFilters,
  IProductItem,
  IProducerItem,
  IClientOrderTableFilters,
  IClientOrderItem,
  ClientOrderedProducts,
  IProducerOrderItem,
  IProducerOrderTableFilters,
  IProductStatsFilters,
  IProductStatsItem,
  IProfitabilityStatsFilters,
  IBestSellingProductsStatsFilter,
  IProfitabilityItem,
  IUpdateLogisticsCost,
  IRecalculateStatistics,
  IImportProductItem,
  INotifyStorageAboutProducerOrder,
  IProducerOrderProducts,
  INotifyStorageAboutClientOrder,
  IAddAvailabilityToProducerOrder,
  IWineTypeItem,
  ITastingItem,
  ITastingClient,
  ITastingProduct,
  ISalesStatisticsRegionwise,
  ISalesStatistics,
  IBestSalesStatistics,
  IClientStatDetailsItem,
  IClientDetailedStatsItem,
  IClientYearlySummariesItem,
  IProfitabilityRealTimeFilters,
  YearlyStat,
  IWineOrderDetails,
  IWineOrderDetailFilters,
  IReservations,
  IAnnualStatParams,
  IProductGeneralStatItem,
  IProductCustomerOrderItem,
  IProductGeographicalChartItem,
  IProductSalesTrendsChartItem,
  IClientOrderFrequencyChartItem,
  IClientRadarChartItem,
  IClientPriceDistChartItem,
  IOverlayItem,
  ILogisticsStats,
  IOrderStats,
  ICurrentMonthStats,
  ICustomerServedItem,
  IProducerSalesTrendsChartItem,
  ICustomerOrders,
  commissionItem,
  commissionItemData,
  WineRevenueStatistics,
  IUserOrderDetails,
  IServiceZoneItem,
  IStockAnalysisOptimizationItem,
  IReorderProductItem,
  ICurrentQuarterStats,
  IProductPerformanceFilters,
  IProductPerformanceStats,
  IProducerOrderPaymentDetails,
  IAnalyticsFiltersParams,
  IPriceDistChartItem,
  IRadarChartItem,
  ITargetItem,
  IStockOptimizationStat,
  IProducerClientRegionExclusionsParams,
  IPriceRangeStockBehaviourStats,
  IPriceRangeStockBehaviourFilters,
  IWineCategoriesResponse,
  IFlowAnalysisFilters,
  IFlowAnalysisStats,
  IDiscounts,
} from 'src/types';
// import { IPostItem } from 'src/types/blog';

import useGetData from 'src/hooks/use-get-data';
import { ICourier } from 'src/types/courier';

const isLegacy = {
  isLegacy: true,
};

export function useGetClientOrderList(params?: IListFilters<IClientOrderTableFilters>) {
  return useGetData<IListFilters<IClientOrderTableFilters>, APIListResponse<IClientOrderItem[]>>({
    endpoint: endpoints.admin.orders.client_orders,
    params,
  });
}

export function useGetClientOrder(params?: IFetchBaseParams) {
  return useGetData<IFetchBaseParams, IClientOrderItem>({
    endpoint: params?.order_code
      ? `${endpoints.admin.orders.client_orders}/${params?.order_code}`
      : null,
  });
}

export function useGetUsersOrder(params?: IFetchBaseParams) {
  return useGetData<IFetchBaseParams, IUserOrderDetails>({
    endpoint: `${endpoints.admin.userOrder}/${params?.slug}`,
  });
}

export function useGetProducerOrderList(params?: IListFilters<IProducerOrderTableFilters>) {
  return useGetData<IListFilters<IProducerOrderTableFilters>, APIListResponse<IProducerOrderItem>>({
    endpoint: `${endpoints.admin.orders.producer_orders}`,
    params,
  });
}

export function useGetProducerOrder(params?: IFetchBaseParams) {
  return useGetData<IFetchBaseParams, IProducerOrderItem>({
    endpoint: params?.order_code
      ? `${endpoints.admin.orders.producer_orders}/${params?.order_code}`
      : null,
    params,
  });
}

export function useGetProducerOrderPaymentDetails(params?: IFetchBaseParams) {
  return useGetData<IFetchBaseParams, IProducerOrderPaymentDetails>({
    endpoint: params?.producer_order_id
      ? `${endpoints.admin.orders.producer_order_payments}/${params?.producer_order_id}`
      : null,
    params,
  });
}

export async function addNewProducerPayment(params?: IFetchBaseParams) {
  const URL = `${endpoints.admin.orders.producer_order_payments}/${params?.producer_order_id}`;
  const res = await axiosInstance.post(URL, params);
  return res;
}

// Delete supplier partial payment
export async function deleteProducerPayment(params?: IFetchBaseParams) {
  const URL = `${endpoints.admin.orders.producer_order_payments}/${params?.id}/delete-payment`;
  await axiosInstance.delete(URL);
}

export function useGetProductList(params?: IListFilters<IProductTableFilters>) {
  return useGetData<IListFilters<IProductTableFilters>, APIListResponse<IOrderProductItem[]>>({
    endpoint: endpoints.admin.products.root,
    params,
  });
}

export function useGetReorderProductList(params?: IListFilters<IProductTableFilters>) {
  return useGetData<IListFilters<IProductTableFilters>, APIListResponse<IReorderProductItem[]>>({
    endpoint: endpoints.admin.products.reorderProducts,
    params,
  });
}

export function useGetProduct(params?: IFetchBaseParams) {
  return useGetData<IFetchBaseParams, IProductItem>({
    endpoint: params?.sku ? `${endpoints.admin.products.root}/${params?.sku}` : null,
  });
}

export function useGetProductGeneralStats(params?: IFetchBaseParams) {
  const queryString = new URLSearchParams({
    vintage: params?.vintage?.toString() ?? '',
  }).toString();
  return useGetData<IFetchBaseParams, IProductGeneralStatItem>({
    endpoint:
      params?.sku && params?.tab
        ? `${endpoints.admin.products.root}/${params?.sku}/${params?.tab}?${queryString}`
        : null,
  });
}

export function useGetProductProducerOrders(params?: IFetchBaseParams) {
  return useGetData<IFetchBaseParams, IProductCustomerOrderItem[]>({
    endpoint:
      params?.sku && params?.tab
        ? `${endpoints.admin.products.root}/${params?.sku}/${params?.tab}`
        : null,
    params,
  });
}
export function useGetProductCustomerOrders(params?: IFetchBaseParams) {
  const queryString = new URLSearchParams(params?.filter as Record<string, string>).toString();
  return useGetData<IFetchBaseParams, IProductCustomerOrderItem[]>({
    endpoint:
      params?.sku && params?.tab
        ? `${endpoints.admin.products.root}/${params?.sku}/${params?.tab}?${queryString}`
        : null,
  });
}

export function useGetGeographicalChart(params?: IFetchBaseParams) {
  const queryString = new URLSearchParams(params?.filter as Record<string, string>).toString();
  return useGetData<IFetchBaseParams, IProductGeographicalChartItem[]>({
    endpoint:
      params?.sku && params?.tab
        ? `${endpoints.admin.products.root}/${params?.sku}/${params?.tab}?${queryString}`
        : null,
  });
}

export function useGetSalesTrendsChart(params?: IFetchBaseParams) {
  const queryString = new URLSearchParams(params?.filter as Record<string, string>).toString();
  return useGetData<IFetchBaseParams, IProductSalesTrendsChartItem[]>({
    endpoint:
      params?.sku && params?.tab
        ? `${endpoints.admin.products.root}/${params?.sku}/${params?.tab}?${queryString}`
        : null,
  });
}

export function useGetProducerSalesTrendsChart(params?: IFetchBaseParams & { slug: string }) {
  const queryString = new URLSearchParams({
    ...(params?.filter as Record<string, string>),
  }).toString();

  return useGetData<IFetchBaseParams, IProducerSalesTrendsChartItem[]>({
    endpoint: params?.slug
      ? `${endpoints.admin.producer}/${params?.slug}/stats/sales-trends?${queryString}`
      : null,
  });
}

export function useGetWineTypeList(params?: IListFilters<{}>) {
  return useGetData<IListFilters<{}>, IWineTypeItem[]>({
    endpoint: endpoints.admin.wineTypes,
    params,
  });
}

export function useGetWineCategoryList(params?: IListFilters<{}>) {
  return useGetData<IListFilters<{}>, IWineTypeItem[]>({
    endpoint: endpoints.admin.wineCategory,
    params,
  });
}

export function useGetWineType(params?: IFetchBaseParams) {
  return useGetData<IFetchBaseParams, IWineTypeItem>({
    endpoint: params?.id ? `${endpoints.admin.wineTypes}/${params?.id}` : null,
  });
}

export function useGetTastingsList(params?: IListFilters<{}>) {
  return useGetData<IListFilters<{}>, ITastingItem[]>({
    endpoint: endpoints.admin.tastings,
    params,
  });
}

export function useGetTastings(params?: IFetchBaseParams) {
  return useGetData<IFetchBaseParams, ITastingItem>({
    // endpoint: params?.id ? `${endpoints.admin.tastings}/${params?.id}` : null,
    endpoint: params?.slug ? `${endpoints.admin.tastings}/${params?.slug}` : null,
  });
}

export function useGetGrapeTypes() {
  return useGetData<undefined, { grape_types: string[] }>({
    endpoint: endpoints.admin.products.grapeTypes,
  });
}

export function useGetProducerList(params?: IListFilters<IProducerTableFilters>) {
  return useGetData<IListFilters<IProducerTableFilters>, APIListResponse<IProducerItem[]>>({
    endpoint: endpoints.admin.producers,
    params,
  });
}

export function useGetProducer(params?: IFetchBaseParams) {
  return useGetData<IFetchBaseParams, IProducerItem>({
    endpoint: params?.slug ? `${endpoints.admin.producers}/${params?.slug}` : null,
    params,
  });
}

export function useGetProducerGeneralStats(params?: IFetchBaseParams) {
  const queryString = new URLSearchParams(params?.filter as Record<string, string>).toString();
  return useGetData<IFetchBaseParams, any>({
    endpoint: params?.slug
      ? `${endpoints.admin.producer}/${params?.slug}/stats?${queryString}`
      : null,
  });
}

export function useGetCustomersServedStats(params?: IFetchBaseParams) {
  const queryString = new URLSearchParams(params?.filter as Record<string, string>).toString();
  return useGetData<IFetchBaseParams, ICustomerServedItem[]>({
    endpoint: params?.slug
      ? `${endpoints.admin.producer}/${params?.slug}/stats/customers-served?${queryString}`
      : null,
  });
}

export function useGetUserList(params?: IListFilters<IUserTableFilters>) {
  return useGetData<IListFilters<IUserTableFilters>, APIListResponse<IUserItem[]>>({
    endpoint: endpoints.admin.users,
    params,
  });
}

export function useGetUser(params: IFetchBaseParams) {
  return useGetData<IFetchBaseParams, IUserItem>({
    endpoint: `${endpoints.admin.users}/${params.slug}`,
    params,
  });
}

export function useGetClientList(params?: IListFilters<IClientTableFilters>) {
  return useGetData<IListFilters<IClientTableFilters>, APIListResponse<IClientItem[]>>({
    endpoint: endpoints.admin.clients,
    params,
  });
}

export function useGetClient(params?: IFetchBaseParams) {
  return useGetData<IFetchBaseParams, IClientItem>({
    endpoint: params?.slug ? `${endpoints.admin.clients}/${params?.slug}/new` : null,
  });
}

export function useGetWineOrdersDetails(slug: string, params?: IWineOrderDetailFilters) {
  return useGetData<IWineOrderDetailFilters, IWineOrderDetails[]>({
    endpoint: slug ? `${endpoints.admin.clients}/${slug}/wineOrders` : null,
    params,
  });
}

export function useGetClientStatDetails(params?: IFetchBaseParams) {
  return useGetData<IFetchBaseParams, IClientStatDetailsItem>({
    endpoint:
      params?.slug && params?.tab
        ? `${endpoints.admin.clients}/${params?.slug}/${params?.tab}`
        : null,
  });
}
export function useGetClientReservationDetails(params?: IFetchBaseParams) {
  return useGetData<IFetchBaseParams, IReservations[]>({
    endpoint:
      params?.slug && params?.tab
        ? `${endpoints.admin.clients}/${params?.slug}/${params?.tab}`
        : null,
  });
}

export function useGetClientDiscountDetails(params?: IFetchBaseParams) {
  return useGetData<IFetchBaseParams, IDiscounts[]>({
    endpoint:
      params?.slug && params?.tab
        ? `${endpoints.admin.clients}/${params?.slug}/${params?.tab}`
        : null,
  });
}

export function useGetClientDetailedStatsDetails(params?: IFetchBaseParams) {
  const queryString = new URLSearchParams(params?.filter as Record<string, string>).toString();
  return useGetData<IFetchBaseParams, IClientDetailedStatsItem>({
    endpoint:
      params?.slug && params?.tab && params?.filter
        ? `${endpoints.admin.clients}/${params?.slug}/${params?.tab}?${queryString}`
        : null,
  });
}

export function useGetClientYearlySummariesDetails(params?: IFetchBaseParams) {
  return useGetData<IFetchBaseParams, IClientYearlySummariesItem>({
    endpoint:
      params?.slug && params?.tab
        ? `${endpoints.admin.clients}/${params?.slug}/${params?.tab}`
        : null,
  });
}

export function useClientOrderFrequencyChartData(params?: IFetchBaseParams) {
  const queryString = new URLSearchParams(params?.filter as Record<string, string>).toString();
  return useGetData<IFetchBaseParams, IClientOrderFrequencyChartItem>({
    endpoint:
      params?.slug && params?.tab
        ? `${endpoints.admin.clients}/${params?.slug}/${params?.tab}?${queryString}`
        : null,
  });
}

export function useGetPriceDistributionChartData(params?: IAnalyticsFiltersParams) {
  return useGetData<IAnalyticsFiltersParams, IPriceDistChartItem>({
    endpoint: endpoints.admin.statistics.analytics.priceDistribution,
    params,
  });
}
export function useGetWineDistributionChartData(params?: IAnalyticsFiltersParams) {
  return useGetData<IAnalyticsFiltersParams, IRadarChartItem>({
    endpoint: endpoints.admin.statistics.analytics.wineDistribution,
    params,
  });
}

export function useClientWineDistributionChartData(params?: IFetchBaseParams) {
  const queryString = new URLSearchParams(params?.filter as Record<string, string>).toString();
  return useGetData<IFetchBaseParams, IClientRadarChartItem>({
    endpoint:
      params?.slug && params?.tab
        ? `${endpoints.admin.clients}/${params?.slug}/${params?.tab}?${queryString}`
        : null,
  });
}

export function useClientPriceDistributionChartData(params?: IFetchBaseParams) {
  const queryString = new URLSearchParams(params?.filter as Record<string, string>).toString();
  return useGetData<IFetchBaseParams, IClientPriceDistChartItem>({
    endpoint:
      params?.slug && params?.tab
        ? `${endpoints.admin.clients}/${params?.slug}/${params?.tab}?${queryString}`
        : null,
  });
}

export function useGetClientDiscount(params?: IFetchBaseParams) {
  return useGetData<IFetchBaseParams, IClientItem>({
    endpoint: `${endpoints.admin.clients}/${params?.slug}/discount`,
    params,
  });
}

export function useGetClientRegions(params?: IFetchBaseParams) {
  return useGetData<unknown, IClientRegions>({
    endpoint: endpoints.admin.clientRegions,
    params,
  });
}

export function useGetClientRegionsProvinces(params?: IFetchBaseParams) {
  return useGetData<unknown, IClientRegionsAndProvinces>({
    endpoint: endpoints.admin.clientRegionsProvinces,
    params,
  });
}

export function useGetClientReferralList(params?: IListFilters<IClientReferralsTableFilters>) {
  return useGetData<
    IListFilters<IClientReferralsTableFilters>,
    APIListResponse<IClientReferralItem[]>
  >({
    endpoint: endpoints.admin.clientReferrals,
    params,
  });
}

export function useGetAmbassadorClientList(params?: IListFilters<IClientReferralsTableFilters>) {
  return useGetData<
    IListFilters<IClientReferralsTableFilters>,
    APIListResponse<IClientReferralItem[]>
  >({
    endpoint: endpoints.admin.ambassadorClients,
    params,
  });
}

export function useGetClientReferral(params?: IFetchBaseParams) {
  return useGetData<IFetchBaseParams, IClientReferralItem>({
    endpoint: `${endpoints.admin.clientReferrals}/${params?.slug}`,
  });
}

export function useGetClientReferralCustomers(params?: IFetchBaseParams) {
  const queryString = new URLSearchParams(params?.filter as Record<string, string>).toString();
  return useGetData<IFetchBaseParams, ICustomerOrders>({
    endpoint: `${endpoints.admin.clientReferrals}/${params?.slug}/${params?.tab}?${queryString}`,
  });
}

export function useGetReservationList(params: IListFilters<IReservationsTableFilters>) {
  return useGetData<IListFilters<IReservationsTableFilters>, APIListResponse<IReservationItem[]>>({
    endpoint: endpoints.admin.reservations,
    params,
  });
}

export function useGetReservation(params: IFetchBaseParams) {
  return useGetData<IFetchBaseParams, IReservationItem>({
    endpoint: `${endpoints.admin.products.reservations(
      params?.sku as string,
      params?.client_slug as string,
    )}`,
  });
}

export function useGetTastingList() {
  return useGetData<IFetchBaseParams, ITastingItem[]>({
    endpoint: endpoints.admin.tastings,
  });
}

export function useGetAnnualStat(params?: IAnnualStatParams) {
  const queryString = new URLSearchParams(params?.filter as Record<string, string>).toString();
  return useGetData<IAnnualStatParams, ISalesStatistics>({
    endpoint: params?.filter
      ? `${endpointsLegacy.admin.statistics.annualStat}/?${queryString}`
      : null,
  });
}
export function useGetWineTypeStat(params?: IAnnualStatParams) {
  const queryString = new URLSearchParams(params?.filter as Record<string, string>).toString();
  return useGetData<IAnnualStatParams, WineRevenueStatistics[]>({
    endpoint: params?.filter
      ? `${endpointsLegacy.admin.statistics.wineType}/?${queryString}`
      : null,
  });
}

export function useGetYoyStats(params?: IAnnualStatParams) {
  const queryString = new URLSearchParams(params?.filter as Record<string, string>).toString();
  return useGetData<IAnnualStatParams, ISalesStatistics>({
    endpoint: params?.filter
      ? `${endpointsLegacy.admin.statistics.getYoyStats}/?${queryString}`
      : null,
  });
}

export function useProfitabilityStats(params: IProfitabilityRealTimeFilters) {
  return useGetData<IProfitabilityRealTimeFilters, YearlyStat>({
    endpoint: endpoints.admin.statistics.profitability,
    params,
  });
}

export function useGetSalesFlowStats(params: IFlowAnalysisFilters) {
  return useGetData<IFlowAnalysisFilters, IFlowAnalysisStats>({
    endpoint: endpoints.admin.statistics.sales_flow,
    params,
  });
}
export function useGetProductPerformanceList(params?: IProductPerformanceFilters) {
  return useGetData<IProductPerformanceFilters, IProductPerformanceStats>({
    endpoint: endpoints.admin.statistics.product_performance,
    params,
  });
}
export function useStatisticsSalesRegionwise(
  params?: IListFilters<IBestSellingProductsStatsFilter>,
) {
  return useGetData<IListFilters<IBestSellingProductsStatsFilter>, ISalesStatisticsRegionwise>({
    endpoint: endpointsLegacy.admin.statistics.saleStats,
    params,
    // ...isLegacy,
  });
}
export function useBestSellingStats(params?: IListFilters<IBestSellingProductsStatsFilter>) {
  return useGetData<IListFilters<IBestSellingProductsStatsFilter>, IBestSalesStatistics>({
    endpoint: endpointsLegacy.admin.statistics.bestSellingProductsStats,
    params,
    // ...isLegacy,
  });
}
export function useGetProductStats(params: IListFilters<IProductStatsFilters>) {
  return useGetData<IListFilters<IProductStatsFilters>, IProductStatsItem[]>({
    endpoint: endpoints.admin.statistics.productStats,
    params,
    // ...isLegacy,
  });
}
export function useGetStockAnalysisOptimization(params: IFetchBaseParams) {
  return useGetData<IFetchBaseParams, IStockAnalysisOptimizationItem>({
    endpoint: `${endpoints.admin.products.root}/${params?.sku}/stats/stock_analysis_optimization`,
  });
}

export function useGetProfitability(params: IListFilters<IProfitabilityStatsFilters>) {
  return useGetData<IListFilters<IProfitabilityStatsFilters>, IProfitabilityItem>({
    endpoint: endpointsLegacy.admin.statistics.root,
    params,
    ...isLegacy,
  });
}

// Mutate API's

export async function createClient(data: IClientItem) {
  const URL = `${endpoints.admin.clients}`;
  const res = await axiosInstance.post(URL, data);
  return res;
}

export async function updateClient(data: IClientItem) {
  const URL = `${endpoints.admin.clients}/${data?.slug}`;
  const res = await axiosInstance.put(URL, data);
  return res;
}

export async function deleteClient(slug: string) {
  const URL = `${endpoints.admin.clients}/${slug}`;
  await axiosInstance.delete(URL);
}

export async function createUser(data: IUserItem) {
  const URL = `${endpoints.admin.users}`;
  const res = await axiosInstance.post(URL, data);
  return res;
}

export async function updateUser(data: IUserItem) {
  const URL = `${endpoints.admin.users}/${data?.slug}`;
  const res = await axiosInstance.put(URL, data);
  return res;
}

export async function deleteUser(slug: string) {
  const URL = `${endpoints.admin.users}/${slug}`;
  await axiosInstance.delete(URL);
}

export async function createReservation(data: IReservationItem) {
  const URL = `${endpoints.admin.products.reservation(data?.sku)}`;
  const res = await axiosInstance.post(URL, data);
  return res;
}

export async function updateReservation(data: IReservationItem) {
  const URL = `${endpoints.admin.products.reservations(data?.sku, data?.client_slug)}`;
  const res = await axiosInstance.put(URL, data);
  return res;
}

export async function deleteReservation(client_slug: string, sku: string) {
  const URL = `${endpoints.admin.products.reservations(sku, client_slug)}`;
  await axiosInstance.delete(URL);
}

export async function createClientReferral(data: IClientReferralItem) {
  const URL = `${endpoints.admin.clientReferrals}`;
  const res = await axiosInstance.post(URL, data);
  return res;
}

export async function updateClientReferral(data: IClientReferralItem) {
  const URL = `${endpoints.admin.clientReferrals}/${data?.slug}`;
  const res = await axiosInstance.put(URL, data);
  return res;
}

export async function deleteClientReferral(slug: string) {
  const URL = `${endpoints.admin.clientReferrals}/${slug}`;
  await axiosInstance.delete(URL);
}

export async function createProduct(data: IProductItem) {
  const URL = `${endpoints.admin.products.root}`;
  const res = await axiosInstance.post(URL, data);
  return res;
}

export async function updateProduct(data: IProductItem) {
  const URL = `${endpoints.admin.products.root}/${data?.sku}`;
  const res = await axiosInstance.put(URL, data);
  return res;
}

export async function deleteProduct(sku: string) {
  const URL = `${endpoints.admin.products.root}/${sku}`;
  await axiosInstance.delete(URL);
}

export async function ImportProducts(data: IImportProductItem[]) {
  const URL = `${endpoints.admin.products.root}/storage_import`;
  const response = await axiosInstance.post(URL, data);
  return response?.data || {};
}

export async function createDiscount(data: IReservationItem) {
  const URL = `${endpoints.admin.products.discount(data?.sku)}`;
  const res = await axiosInstance.post(URL, data);
  return res;
}
export async function deleteDiscount(data: { id: string; product_id: string }) {
  const URL = `${endpoints.admin.products.deleteDiscounts(data.id, data.product_id)}`;
  await axiosInstance.delete(URL);
}

export async function createProducer(data: IProducerItem) {
  const URL = `${endpoints.admin.producers}`;
  const res = await axiosInstance.post(URL, data);
  return res;
}

export async function updateProducer(data: IProducerItem) {
  const URL = `${endpoints.admin.producers}/${data?.slug}`;
  const res = await axiosInstance.put(URL, data);
  return res;
}

export async function deleteProducer(sku: string) {
  const URL = `${endpoints.admin.producers}/${sku}`;
  await axiosInstance.delete(URL);
}

export function useGetProducerRegions(params?: IFetchBaseParams) {
  return useGetData<unknown, IClientRegions>({
    endpoint: endpoints.admin.producerRegions,
    params,
  });
}

export function useGetProducerRegionsProvinces(params?: IFetchBaseParams) {
  return useGetData<unknown, IClientRegionsAndProvinces>({
    endpoint: endpoints.admin.producerRegionsProvinces,
    params,
  });
}

export async function createProducerClientRegionExclusions(
  data: IProducerClientRegionExclusionsParams,
) {
  const URL = `${endpoints.admin.producers}/${data.slug}/producer_client_region_exclusions`;
  await axiosInstance.post(URL, data);
}

export async function deleteProducerClientRegionExclusions(data: {
  region: string;
  province: string;
  slug: string;
}) {
  const URL = `${endpoints.admin.producers}/${data.slug}/producer_client_region_exclusions/${data.region}/${data.province}`;
  await axiosInstance.delete(URL);
}

export async function createClientOrder(data: IClientOrderItem) {
  const URL = `${endpoints.admin.orders.client_orders}`;
  const res = await axiosInstance.post(URL, data);
  return res;
}

export async function updateClientOrder(data: IClientOrderItem) {
  const URL = `${endpoints.admin.orders.client_orders}/${data?.order_code}`;
  const res = await axiosInstance.put(URL, data);
  return res;
}

export async function deleteClientOrder(order_code: string) {
  const URL = `${endpoints.admin.orders.client_orders}/${order_code}`;
  await axiosInstance.delete(URL);
}

export async function createClientOrderProducts(order_code: string, data: ClientOrderedProducts) {
  const URL = `${endpoints.admin.orders.createClientOrderProduct(order_code)}`;
  const res = await axiosInstance.post(URL, data);
  return res;
}

export async function updateClientOrderProducts(order_code: string, data: ClientOrderedProducts) {
  const URL = `${endpoints.admin.orders.updateClientOrderProduct(order_code, data?.id)}`;
  const res = await axiosInstance.put(URL, data);
  return res;
}

export async function deleteClientOrderProduct(order_code: string, id: string) {
  const URL = `${endpoints.admin.orders.updateClientOrderProduct(order_code, id)}`;
  await axiosInstance.delete(URL);
}

export async function createProducerOrder(data: IProducerOrderItem) {
  const URL = `${endpoints.admin.orders.producer_orders}`;
  const res = await axiosInstance.post(URL, data);
  return res;
}

export async function createProducerOrderProducts(
  order_code: string,
  data: IProducerOrderProducts,
) {
  const URL = `${endpoints.admin.orders.producer_orders}/${order_code}/producer_order_products`;
  const res = await axiosInstance.post(URL, data);
  return res;
}

export async function updateProducerOrderProducts(
  order_code: string,
  data: IProducerOrderProducts,
) {
  const URL = `${endpoints.admin.orders.producer_orders}/${order_code}/producer_order_products/${data.id}`;
  const res = await axiosInstance.put(URL, data);
  return res;
}

export async function deleteProducerOrderProduct(order_code: string, id: string) {
  const URL = `${endpoints.admin.orders.producer_orders}/${order_code}/producer_order_products/${id}`;
  await axiosInstance.delete(URL);
}

export async function updateProducerOrder(data: IProducerOrderItem) {
  const URL = `${endpoints.admin.orders.producer_orders}/${data?.order_code}`;
  const res = await axiosInstance.put(URL, data);
  return res;
}

export async function deleteProducerOrder(order_code: string) {
  const URL = `${endpoints.admin.orders.producer_orders}/${order_code}`;
  await axiosInstance.delete(URL);
}

export async function notifyStorageAboutProducerOrder({
  order_code,
  preliminary,
}: INotifyStorageAboutProducerOrder) {
  const URL = `${endpoints.admin.orders.notifyStorageAboutProducerOrder(order_code)}`;
  const res = await axiosInstance.post(URL, preliminary ? { preliminary } : {});
  return res;
}

export async function addAvailabilityToProducerOrder({
  order_code,
}: IAddAvailabilityToProducerOrder) {
  const URL = `${endpoints.admin.orders.addAvailability(order_code)}`;
  const response = await axiosInstance.put(URL);
  return response;
}

export async function updateLogisticsCost(data: IUpdateLogisticsCost) {
  const URL = `${endpoints.admin.statistics.updateLogisticsCost}`;
  await axiosInstance.post(URL, data);
}

export async function recalculateStatistics(data: IRecalculateStatistics) {
  const URL = `${endpoints.admin.statistics.root}`;
  await axiosInstance.post(URL, data);
}

export async function notifyStorageAboutClientOrder({
  order_code,
  storage_notified,
  preliminary,
}: INotifyStorageAboutClientOrder) {
  const data: any = {};
  const URL = `${endpoints.admin.orders.notifyStorageAboutClientOrder(order_code)}`;

  if (storage_notified) {
    data.storage_notified = storage_notified;
  }
  if (preliminary) {
    data.preliminary = preliminary;
  }
  const res = await axiosInstance.post(URL, data);
  return res;
}

export async function proFormaInvoiced({ order_code }: INotifyStorageAboutClientOrder) {
  const URL = `${endpoints.admin.orders.proFormaClientOrder(order_code)}`;
  const res = await axiosInstance.post(URL, {});
  return res;
}

export async function createWineType(data: IWineTypeItem) {
  const URL = `${endpoints.admin.wineTypes}`;
  const res = await axiosInstance.post(URL, data);
  return res;
}

export async function updateWineType(data: IWineTypeItem) {
  const URL = `${endpoints.admin.wineTypes}/${data?.id}`;
  const res = await axiosInstance.put(URL, data);
  return res;
}

export async function createTastings(data: ITastingItem) {
  const URL = `${endpoints.admin.tastings}`;
  const res = await axiosInstance.post(URL, data);
  return res;
}

export async function createTastingsClient(params: string, body: ITastingClient) {
  const URL = `${endpoints.admin.createClient(params)}`;
  const res = await axiosInstance.post(URL, body);
  return res;
}

export async function deleteTastingsClient(params: { slug: string; clientSlug: string }) {
  const URL = `${endpoints.admin.deleteClient(params)}`;
  const res = await axiosInstance.delete(URL);
  return res;
}

export async function createTastingsProduct(params: string, body: ITastingProduct) {
  const URL = `${endpoints.admin.createProduct(params)}`;
  const res = await axiosInstance.post(URL, body);
  return res;
}

export async function deleteTastingsProduct(params: { slug: string; sku: string }) {
  const URL = `${endpoints.admin.deleteProduct(params)}`;
  const res = await axiosInstance.delete(URL);
  return res;
}

export function useGetCourierList() {
  return useGetData<IListFilters<IClientTableFilters>, ICourier[]>({
    endpoint: endpoints.admin.courier,
  });
}
// overlay API's

export async function createOverlay(data: IOverlayItem) {
  const URL = `${endpoints.admin.overlay.root}`;
  const res = await axiosInstance.post(URL, data);
  return res;
}

export async function updateOverlay(data: IOverlayItem) {
  const URL = `${endpoints.admin.overlay.root}/${data?.id}`;
  const res = await axiosInstance.put(URL, data);
  return res;
}

export async function deleteOverlay(overlayId: string) {
  const URL = `${endpoints.admin.overlay.root}/${overlayId}`;
  await axiosInstance.delete(URL);
}

export function useGetOverlayList() {
  return useGetData<IFetchBaseParams, IOverlayItem>({
    endpoint: `${endpoints.admin.overlay.root}`,
  });
}

export function useGetOverlay(params?: IFetchBaseParams) {
  return useGetData<IFetchBaseParams, IOverlayItem>({
    endpoint: params?.overlayId ? `${endpoints.admin.overlay.root}/${params?.overlayId}` : null,
  });
}

export function useGetClientActiveOverlayForpdf() {
  return useGetData<Partial<IFetchBaseParams>, IOverlayItem>({
    endpoint: endpoints.admin.overlay.activePDF,
  });
}

export function useGetLogisticsStats(params?: IFetchBaseParams) {
  return useGetData<IFetchBaseParams, ILogisticsStats>({
    endpoint: `${endpoints.admin.overview.logistics}`,
    params,
  });
}

export function useGetCurrentMonthStats() {
  return useGetData<IFetchBaseParams, ICurrentMonthStats>({
    endpoint: `${endpoints.admin.overview.currentMonthStats}`,
  });
}

export function useGetCurrentQuarterStats() {
  return useGetData<IFetchBaseParams, ICurrentQuarterStats>({
    endpoint: `${endpoints.admin.overview.currentQuarterStats}`,
  });
}
export function useGetOptimizationTarget() {
  return useGetData<IFetchBaseParams, ITargetItem>({
    endpoint: `${endpoints.admin.overview.optimizationTarget}`,
  });
}

export async function createTarget(data: ITargetItem) {
  const URL = `${endpoints.admin.overview.target}`;
  const res = await axiosInstance.post(URL, data);
  return res;
}
export function useStockOptimizationStats(params: IProfitabilityRealTimeFilters) {
  return useGetData<IProfitabilityRealTimeFilters, IStockOptimizationStat>({
    endpoint: endpoints.admin.statistics.stock_optimization,
    params,
  });
}
export function usePriceRangeStockBehaviourStats(params: IPriceRangeStockBehaviourFilters) {
  return useGetData<IPriceRangeStockBehaviourFilters, IPriceRangeStockBehaviourStats>({
    endpoint: endpoints.admin.statistics.price_range_stock_behaviour,
    params,
  });
}
export function useGetWineCategoriesData() {
  return useGetData<null, IWineCategoriesResponse>({
    endpoint: endpoints.admin.wineCategories,
  });
}

export function useGetAdministrationStats() {
  return useGetData<IFetchBaseParams, IOrderStats[]>({
    endpoint: `${endpoints.admin.overview.administration}`,
  });
}
export async function sendClientPdfLink({ slug }: { slug: string }) {
  const URL = `${endpoints.admin.clients}/${slug}/pdf/email/send`;
  const res = await axiosInstance.post(URL);
  return res;
}
// create agent commission
export async function createAgentCommission(data: commissionItem) {
  const URL = `${endpoints.admin.agent}/${data.agentId}/yearly-commission`;
  const res = await axiosInstance.post(URL, { date: data.date, amount: data.amount });

  return res;
}
// get agent commission
export function useGetAgentCommission({
  agentId,
  sortOrder,
}: {
  agentId: number;
  sortOrder: string;
}) {
  const queryString = new URLSearchParams({
    sortOrder: sortOrder ?? '',
  }).toString();
  return useGetData<IFetchBaseParams, commissionItemData>({
    endpoint: `${endpoints.admin.agent}/${agentId}/yearly-commission?${queryString}`,
  });
}
// // update agent commission
// export async function updateAgentCommission(data: commissionItem) {
//   const URL = `${endpoints.admin.agent}/${data.agentId}/yearly-commission`;
//   const res = await axiosInstance.put(URL, data);
//   return res;
// }
// delete agent commission
export async function deleteAgentCommission({ id, agentId }: { id: string; agentId: string }) {
  const queryString = new URLSearchParams({
    id: id ?? null,
  }).toString();
  const URL = `${endpoints.admin.agent}/${agentId}/yearly-commission?${queryString}`;

  await axiosInstance.delete(URL);
}

// create agent service zone
export async function createAgentserviceZone({
  agentId,
  data,
}: {
  agentId: string;
  data: IServiceZoneItem;
}) {
  const payload = {
    region_id: data?.region,
    province_ids: data?.provinces,
  };

  const URL = `${endpoints.admin.agentServiceZone}/${agentId}`;
  const res = await axiosInstance.post(URL, payload);

  return res;
}
// get agent service zone
export function useGetAgentServiceZone({ agentId }: { agentId: string | number }) {
  return useGetData<IFetchBaseParams, commissionItemData>({
    endpoint: agentId ? `${endpoints.admin.agentServiceZone}/${agentId}` : null,
  });
}
export async function deleteAgentServiceZone({
  agentId,
  regionId,
}: {
  agentId: string;
  regionId: string;
}) {
  const URL = `${endpoints.admin.agentServiceZone}/${agentId}/${regionId}`;
  await axiosInstance.delete(URL);
}
