// lib
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';
// react
import { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
// mui
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
// api
import { createTarget, useGetOptimizationTarget } from 'src/api/admin';
// component
import FormProvider, { RHFDecimalField } from 'src/components/hook-form';
// hooks
import { useLocales } from 'src/locales';
import { DatePicker } from '@mui/x-date-pickers';

export default function CreateTarget({ dialog }: { dialog: any }) {
  const { t } = useLocales('financialPage');

  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const NewTargetSchema = Yup.object().shape({
    targetDIO: Yup.number(),
    targetDate: Yup.date(),
    targetReductionRate: Yup.number(),
  });

  const { data: optimizationTarget } = useGetOptimizationTarget();

  const defaultValues = useMemo(
    () => ({
      targetDIO: optimizationTarget?.targetDIO || 0,
      targetReductionRate: optimizationTarget?.targetReductionRate || 0,
      targetDate: optimizationTarget?.targetDate ? new Date(optimizationTarget?.targetDate) : null,
    }),
    [
      optimizationTarget?.targetDIO,
      optimizationTarget?.targetDate,
      optimizationTarget?.targetReductionRate,
    ],
  );

  const methods = useForm({
    resolver: yupResolver<any>(NewTargetSchema),
    defaultValues,
  });

  const {
    reset,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (optimizationTarget) {
      reset(defaultValues);
    }
  }, [optimizationTarget, reset, defaultValues]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      if (data) {
        const finalData = { ...data };
        await createTarget(finalData);
        reset();
        dialog.onFalse();
        enqueueSnackbar(`${t('create_success')}!`);
      } else {
        enqueueSnackbar(`${t('failed')}!`, { variant: 'error' });
      }
    } catch (error) {
      if (error?.response?.data) {
        enqueueSnackbar(error?.response?.data?.message, { variant: 'error' });
      }
    }
  });

  const renderDetails = (
    <Grid item xs={12}>
      <Stack spacing={3}>
        <Stack spacing={1.5}>
          <Typography variant="subtitle2">{t('target_dio')}</Typography>
          <RHFDecimalField name="targetDIO" />
        </Stack>
        <Stack spacing={1.5}>
          <Typography variant="subtitle2">{t('target_reduction_rate')}</Typography>
          <RHFDecimalField name="targetReductionRate" />
        </Stack>
        <Stack spacing={1.5}>
          <Typography variant="subtitle2">{t('target_date')}</Typography>
          <Controller
            name="targetDate"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <DatePicker
                value={field.value}
                format="dd/MM/yyyy"
                onChange={(newValue) => {
                  field.onChange(newValue);
                }}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    error: !!error,
                    helperText: error?.message,
                  },
                }}
              />
            )}
          />
        </Stack>
      </Stack>
    </Grid>
  );

  const renderActions = (
    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Button onClick={() => dialog.onFalse()}>{t('cancel')}</Button>
      <LoadingButton type="submit" size="large" loading={isSubmitting} sx={{ ml: 2 }} color="error">
        {t('set')}
      </LoadingButton>
    </Grid>
  );

  return (
    <Dialog
      open={dialog.value}
      onClose={dialog.onFalse}
      maxWidth={isExtraSmallScreen ? false : 'md'}
      PaperProps={{
        sx: {
          width: isExtraSmallScreen ? '100%' : 'auto',
          padding: 1,
        },
      }}
    >
      <DialogTitle>{`${t('set')} ${t('target')}`}</DialogTitle>
      <DialogContent>
        <FormProvider methods={methods} onSubmit={onSubmit}>
          <Grid container spacing={{ xs: 1, sm: 3 }}>
            {renderDetails}
            {renderActions}
          </Grid>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}
