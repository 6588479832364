// lib
import v from 'voca';
// react
import React, { useState, useEffect } from 'react';
// mui
import { Box, Chip, Paper, Stack, Button } from '@mui/material';
import { StackProps } from '@mui/material/Stack';
// utils
import { useLocales } from 'src/locales';
import { UNPAID_CLIENT_ORDER_TABLE_RESULT_REPLACEMENT_LABEL_KEY } from 'src/utils/xp-utils';
import { convertToTitleCase } from 'src/utils/misc';
// components
import Iconify from 'src/components/iconify';
// type
import { IUnpaidOrderTableFilters } from 'src/types/unpaid-orders';

//----------------------------------------------------------------------------------
export interface props {
  unpaidOrderFilters: IUnpaidOrderTableFilters;
  totalRows: number;
  defaultFilters: IUnpaidOrderTableFilters;
  handleResetAllFilters: () => void;
  canClearFilter: boolean;
  handleSingleRemoveFilter: (key: string) => void;
}

//-------------------------------------------------------------------------------------------
function UnpaidOrderFilterResult({
  unpaidOrderFilters,
  totalRows,
  defaultFilters,
  handleResetAllFilters,
  canClearFilter,
  handleSingleRemoveFilter,
}: props) {
  const { t } = useLocales();

  const [filterList, setFilterList] = useState<{ key: string; value: string; exactKey: string }[]>(
    [],
  );

  useEffect(() => {
    if (unpaidOrderFilters) {
      setFilterList(
        Object.entries(unpaidOrderFilters).map(([key, value]) => ({
          key: UNPAID_CLIENT_ORDER_TABLE_RESULT_REPLACEMENT_LABEL_KEY[
            key as keyof typeof UNPAID_CLIENT_ORDER_TABLE_RESULT_REPLACEMENT_LABEL_KEY
          ],
          value: typeof value === 'boolean' ? String(value) : value,
          exactKey: key,
        })),
      );
    }
  }, [unpaidOrderFilters]);

  return (
    canClearFilter && (
      <Stack spacing={1.5} px={2.5} mb={2}>
        <Box sx={{ typography: 'body2' }}>
          <strong>{totalRows}</strong>&nbsp;
          <Box component="span" sx={{ color: 'text.secondary', ml: 0.25 }}>
            {t('results_found')}
          </Box>
        </Box>

        <Stack flexGrow={1} spacing={1} direction="row" flexWrap="wrap" alignItems="center">
          {filterList.map(
            (obj) =>
              obj.value &&
              defaultFilters[obj.exactKey as keyof {}] !== obj.value && (
                <Block key={obj.key} label={`${t(v.snakeCase(obj.key))}:`}>
                  <Chip
                    label={convertToTitleCase(t(obj.value))}
                    size="small"
                    onDelete={() => handleSingleRemoveFilter(obj.exactKey)}
                  />
                </Block>
              ),
          )}
          {canClearFilter && (
            <Button
              color="error"
              onClick={handleResetAllFilters}
              startIcon={<Iconify icon="solar:trash-bin-trash-bold" />}
            >
              {t('clear')}
            </Button>
          )}
        </Stack>
      </Stack>
    )
  );
}

type BlockProps = StackProps & {
  label: string;
};

function Block({ label, children, sx, ...other }: BlockProps) {
  return (
    <Stack
      component={Paper}
      variant="outlined"
      spacing={1}
      direction="row"
      sx={{
        p: 1,
        borderRadius: 1,
        overflow: 'hidden',
        borderStyle: 'dashed',
        ...sx,
      }}
      {...other}
    >
      <Box component="span" sx={{ typography: 'subtitle2' }}>
        {label}
      </Box>

      <Stack spacing={1} direction="row" flexWrap="wrap">
        {children}
      </Stack>
    </Stack>
  );
}

export default React.memo(UnpaidOrderFilterResult);
