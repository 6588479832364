import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
// utils
import { localStorageGetItem } from 'src/utils/storage-available';
//
import { defaultLang } from './config-lang';
//
import translationEn from './langs/en.json';
import translationIt from './langs/it.json';

// ----------------------------------------------------------------------

const lng = localStorageGetItem('i18nextLng', defaultLang.value);

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translations: translationEn.common,
        // Statistics section
        analyticsPage: translationEn.statistics.analyticsPage,
        productPerformancePage: translationEn.statistics.productPerformancePage,
        bottlesFlowPage: translationEn.statistics.bottlesFlowPage,
        stockOptimizationPage: translationEn.statistics.stockOptimizationPage,
        profitabilityPage: translationEn.statistics.profitabilityPage,
        productStatisticsPage: translationEn.statistics.productStatisticsPage,
        flowAnalysisPage: translationEn.statistics.flowAnalysisPage,
        annualStatisticsPage: translationEn.statistics.annualStatisticsPage,
        salesStatisticsPage: translationEn.statistics.salesStatisticsPage,
        priceRangeStockBehaviourPage: translationEn.statistics.priceRangeStockBehaviourPage,
        // Dashboard section
        financialPage: translationEn.dashboard.financialPage,
        // Client section
        agentPage: translationEn.client.agentPage,
        tastingsPage: translationEn.client.tastingsPage,
        reservationsPage: translationEn.client.reservationsPage,
        clientsPage: translationEn.client.clientsPage,
        usersPage: translationEn.client.usersPage,
        // Products section
        productsPage: translationEn.products.productsPage,
        reorderProductsPage: translationEn.products.reorderProductsPage,
        producersPage: translationEn.products.producersPage,
        wineCategoryPage: translationEn.products.wineCategoryPage,
      },
      it: {
        translations: translationIt.common,
        // Statistics section
        analyticsPage: translationIt.statistics.analyticsPage,
        productPerformancePage: translationIt.statistics.productPerformancePage,
        bottlesFlowPage: translationIt.statistics.bottlesFlowPage,
        stockOptimizationPage: translationIt.statistics.stockOptimizationPage,
        profitabilityPage: translationIt.statistics.profitabilityPage,
        productStatisticsPage: translationIt.statistics.productStatisticsPage,
        flowAnalysisPage: translationIt.statistics.flowAnalysisPage,
        annualStatisticsPage: translationIt.statistics.annualStatisticsPage,
        salesStatisticsPage: translationIt.statistics.salesStatisticsPage,
        priceRangeStockBehaviourPage: translationIt.statistics.priceRangeStockBehaviourPage,
        // Dashboard section
        financialPage: translationIt.dashboard.financialPage,
        // Client section
        agentPage: translationIt.client.agentPage,
        tastingsPage: translationIt.client.tastingsPage,
        reservationsPage: translationIt.client.reservationsPage,
        clientsPage: translationIt.client.clientsPage,
        usersPage: translationIt.client.usersPage,
        // Products section
        productsPage: translationIt.products.productsPage,
        reorderProductsPage: translationIt.products.reorderProductsPage,
        producersPage: translationIt.products.producersPage,
        wineCategoryPage: translationIt.products.wineCategoryPage,
      },
    },
    lng,
    fallbackLng: lng,
    debug: false,
    ns: [
      'translations',
      // Statistics section
      'analyticsPage',
      'productPerformancePage',
      'bottlesFlowPage',
      'stockOptimizationPage',
      'profitabilityPage',
      'productStatisticsPage',
      'flowAnalysisPage',
      'financialPage',
      'priceRangeStockBehaviourPage',
      // Client section
      'agentPage',
      'tastingsPage',
      'reservationsPage',
      'clientsPage',
      'usersPage',
      // products section
      'productsPage',
      'reorderProductsPage',
      'producersPage',
      'wineCategoryPage',
    ],

    defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
