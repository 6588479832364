import { Box, Card, Container, Table, TableBody, TableContainer } from '@mui/material';
import { useGetClientOrderList } from 'src/api/admin';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import Scrollbar from 'src/components/scrollbar';
import { useSettingsContext } from 'src/components/settings';
import { useLocales } from 'src/locales';
import { useCallback, useEffect, useMemo, useState, Children } from 'react';

import {
  useTable,
  TableNoData,
  TablePaginationCustom,
  TableHead,
  TableSearch,
  getComparator,
} from 'src/components/table';
import { isEqual } from 'lodash';
import { removeFalsyValuesFromObject } from 'src/utils/misc';
import { TABLE_DEFAULTS } from 'src/constants/misc';
import { useDebounce } from 'src/hooks/use-debounce';

import { fConvertDateFormat } from 'src/utils/format-date';

import {
  IUnpaidOrderItem,
  IUnpaidOrderTableFilters,
  IUnpaidOrderTableFilterValue,
} from 'src/types/unpaid-orders';
import UnpaidOrderTableFilters from '../list/unpaid-order-table-filters';
import UnpaidOrderFilterResult from '../list/unpaid-order-filter-result';
import UnpaidOrdersTableRow from '../list/unpaid-order-table-row';

const defaultFilters = {
  searchText: '',
  year: new Date().getFullYear().toString(),
  adminOnly: 'yes',
  paid: 'no',
  month: '',
  invoiceDueDate: 'true',
};

export default function UnpaidClientOrdersView() {
  const { t } = useLocales();

  // table headers
  const TABLE_COLUMNS = useMemo(
    () => [
      { field: 'order_date', title: t('date'), minWidth: 100 },
      { field: 'order_code', title: t('id'), minWidth: 150 },
      { field: 'client_name', title: t('client'), minWidth: 200 },
      { field: 'total_end_price', title: t('€'), align: 'right' as const },
      { field: 'invoice_due_date', title: t('due_date'), minWidth: 100 },
      { field: 'overdue', title: t('overdue'), minWidth: 100 },
      { field: '', title: '' },
    ],
    [t],
  );

  const table = useTable({
    defaultRowsPerPage: 50, // Default rows per page
    defaultOrderBy: 'invoice_due_date', // Default sort by invoice due date
    defaultOrder: 'asc', // Sort in ascending order
  });

  const settings = useSettingsContext();

  const [tableData, setTableData] = useState<IUnpaidOrderItem[]>([]);

  const [filters, setFilters] = useState<IUnpaidOrderTableFilters>(defaultFilters);

  const canReset = !isEqual(defaultFilters, filters);

  const notFound = (!tableData.length && canReset) || !tableData.length;

  const tableFiltersHandler = useCallback(
    ({ fetchAll = false }: { fetchAll?: boolean }) =>
      removeFalsyValuesFromObject({
        ...(fetchAll
          ? { all: true }
          : {
              page: table.page + 1,
              limit: table.rowsPerPage,
            }),
        sortBy: table.orderBy,
        sortDirection: table.order,
      }),
    [table],
  );

  const apiFiltersHandler = useCallback(() => {
    const allFilters: Partial<IUnpaidOrderTableFilters> = { ...filters };

    if (allFilters.searchText) {
      delete allFilters.searchText;
    }

    return removeFalsyValuesFromObject({ ...allFilters });
  }, [filters]);

  const debouncedSearchText = useDebounce(filters.searchText, 1500);

  const { data, isLoading } = useGetClientOrderList(
    removeFalsyValuesFromObject({
      ...apiFiltersHandler(),
      ...tableFiltersHandler({ fetchAll: false }),
      search: debouncedSearchText,
    }),
  );

  const {
    currentPage = TABLE_DEFAULTS.CURRENT_PAGE,
    totalRows = TABLE_DEFAULTS.TOTAL_PAGES,
    unpaid_orders = [],
  } = useMemo(
    () => ({
      currentPage:
        (Number(data?.current_page) || 0) - ((Number(data?.current_page) || 0) === 0 ? 0 : 1),
      totalPages: data?.total_pages,
      unpaid_orders: data?.data,
      totalRows: data?.total_rows,
    }),
    [data],
  );

  const handleFilters = useCallback(
    (name: string, value: IUnpaidOrderTableFilterValue) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table],
  );

  const tableDataModifier = useCallback(
    (rows: IUnpaidOrderItem[]) =>
      rows?.map((row) => ({
        ...row,
        order_date: fConvertDateFormat(row?.order_date || ''),
        invoice_due_date: fConvertDateFormat(row?.invoice_due_date || ''),
      })) || [],
    [],
  );

  useEffect(() => {
    if (Array.isArray(unpaid_orders)) {
      const rows = tableDataModifier(unpaid_orders);
      setTableData(rows as IUnpaidOrderItem[]);
    }
  }, [unpaid_orders, tableDataModifier]);

  // handling clear all filters
  const handleResetFilters = () => {
    setFilters(defaultFilters);
  };

  // handle clear single filter
  const handleSingleRemoveFilter = (key: string) => {
    setFilters((prev) => ({ ...prev, [key]: defaultFilters[key as keyof {}] }));
  };

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(
      table.order,
      table.orderBy,
      ['order_date', 'invoice_due_date'].includes(table.orderBy),
    ),
  });

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading={t('unpaid_orders')}
        links={[{ name: t('admin') }, { name: t('unpaid_orders') }]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      <Card>
        <Box
          display="flex"
          flexDirection={{ xs: 'row', sm: 'row' }}
          alignItems="center"
          px={{ xs: 1, sm: 2.5 }}
          py={1}
          mb={1}
        >
          <TableSearch<IUnpaidOrderTableFilters>
            filters={filters}
            onFilters={handleFilters}
            loading={isLoading}
          />
          <UnpaidOrderTableFilters
            filters={filters}
            setFilters={setFilters}
            canReset={canReset}
            handleResetAllFilters={handleResetFilters}
          />
        </Box>

        <UnpaidOrderFilterResult
          unpaidOrderFilters={filters}
          totalRows={totalRows}
          defaultFilters={defaultFilters}
          handleResetAllFilters={handleResetFilters}
          canClearFilter={canReset}
          handleSingleRemoveFilter={handleSingleRemoveFilter}
        />
        <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
          <Scrollbar>
            <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
              <TableHead
                order={table.order}
                orderBy={table.orderBy}
                headLabel={TABLE_COLUMNS}
                onSort={table.onSort}
              />

              <TableBody>
                {Children.toArray(
                  dataFiltered.map((row: IUnpaidOrderItem) => (
                    <UnpaidOrdersTableRow row={row} headLabel={TABLE_COLUMNS} />
                  )),
                )}
                <TableNoData notFound={notFound} />
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>

        <TablePaginationCustom
          count={totalRows}
          page={currentPage}
          rowsPerPage={table.rowsPerPage}
          onPageChange={table.onChangePage}
          onRowsPerPageChange={table.onChangeRowsPerPage}
          dense={table.dense}
          onChangeDense={table.onChangeDense}
        />
      </Card>
    </Container>
  );
}

// apply filter
function applyFilter({
  inputData,
  comparator,
}: {
  inputData: IUnpaidOrderItem[];
  comparator: (a: any, b: any) => number;
}) {
  inputData.sort((a, b) => {
    const sortOrder = comparator(a, b);
    return sortOrder;
  });

  return inputData;
}
