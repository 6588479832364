// ----------------------------------------------------------------------

// import { isValid, parse } from 'date-fns';

export function emptyRows(page: number, rowsPerPage: number, arrayLength: number) {
  return page ? Math.max(0, (1 + page) * rowsPerPage - arrayLength) : 0;
}

function isNumeric(str: string) {
  if (typeof str !== 'string') return false;
  // @ts-ignore
  return !Number.isNaN(str) && !Number.isNaN(parseFloat(str));
}

// Function to parse a date string to a Date object
// function parseDate(dateStr: string): Date | null {
//   const parsedDate = parse(dateStr, 'yyyy-MM-dd', new Date());
//   return isValid(parsedDate) ? parsedDate : null;
// }

function convertStrToNoIfNo(str: number | string) {
  if (typeof str === 'number') return str;

  if (isNumeric(str as string)) {
    return parseFloat(str as string);
  }

  return String(str).toLowerCase();
}

// Helper function to validate the date format (DD-MM-YYYY)
function isValidDateFormat(dateStr: string): boolean {
  const regex = /^\d{2}-\d{2}-\d{4}$/;
  return regex.test(dateStr);
}

// Helper function to correct the date format if needed
function correctDateFormat(dateStr: string): string | null {
  // Check if the date is in an invalid format and attempt to fix it
  const parts = dateStr.split('-');
  if (parts.length === 3) {
    const [day, month, year] = parts;
    // Ensure each part is a valid number and falls within expected ranges
    if (
      parseInt(day, 10) > 0 &&
      parseInt(day, 10) <= 31 &&
      parseInt(month, 10) > 0 &&
      parseInt(month, 10) <= 12 &&
      year.length === 4
    ) {
      return `${day}-${month}-${year}`; // Return the corrected format
    }
  }
  return null; // Invalid date format that can't be corrected
}

// Helper function to check if a string is a valid date
function isValidDate(dateStr: string): boolean {
  const [day, month, year] = dateStr.split('-').map(Number);
  const date = new Date(year, month - 1, day);
  return date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T, isDate: boolean = false) {
  if (isDate) {
    // Validate and correct the date format if necessary
    let dateA = a[orderBy] as string;
    let dateB = b[orderBy] as string;

    if (!isValidDateFormat(dateA)) {
      const correctedDateA = correctDateFormat(dateA);
      if (correctedDateA && isValidDate(correctedDateA)) {
        dateA = correctedDateA;
      }
    }

    if (!isValidDateFormat(dateB)) {
      const correctedDateB = correctDateFormat(dateB);
      if (correctedDateB && isValidDate(correctedDateB)) {
        dateB = correctedDateB;
      }
    }

    const dateATime = dateA ? new Date(dateA.split('-').reverse().join('-')).getTime() : null;
    const dateBTime = dateB ? new Date(dateB.split('-').reverse().join('-')).getTime() : null;

    // Perform date comparisons
    if (dateATime === null && dateBTime === null) {
      return 0; // Both dates are null, consider them equal
    }
    if (dateATime === null) {
      return 1; // dateA is null, so it should come after dateB
    }
    if (dateBTime === null) {
      return -1; // dateB is null, so it should come after dateA
    }
    // Compare dates directly
    if (dateATime > dateBTime) {
      return -1; // dateA is later, so it should come before dateB (descending order)
    }
    if (dateATime < dateBTime) {
      return 1; // dateA is earlier, so it should come after dateB (descending order)
    }
    return 0; // Dates are equal
  }

  // Normal sorting for non-date values
  const convertedValOfA = convertStrToNoIfNo(a[orderBy] as string | number);
  const convertedValOfB = convertStrToNoIfNo(b[orderBy] as string | number);

  if (convertedValOfA === null) {
    return 1;
  }
  if (convertedValOfB === null) {
    return -1;
  }
  if (convertedValOfB < convertedValOfA) {
    return -1;
  }
  if (convertedValOfB > convertedValOfA) {
    return 1;
  }
  return 0;
}

function createModifiedComparator<Key extends keyof any>(
  order: 'asc' | 'desc',
  orderBy: Key,
  isDate: boolean = false,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return (a, b) => {
    // Custom values to ignore
    const customValuesToIgnore = ['----', '---', '--', '-'];

    // Handle custom values and falsy values
    const valueA = a[orderBy];
    const valueB = b[orderBy];

    // Ignore custom values
    if (
      customValuesToIgnore.includes(valueA as string) &&
      customValuesToIgnore.includes(valueB as string)
    )
      return 0;
    if (customValuesToIgnore.includes(valueA as string)) return 1;
    if (customValuesToIgnore.includes(valueB as string)) return -1;

    // Allow 0 but treat other falsy values (e.g., null, undefined, "")
    const isFalsy = (value: any) => value == null || value === '';

    if (isFalsy(valueA) && isFalsy(valueB)) return 0;
    if (isFalsy(valueA)) return 1;
    if (isFalsy(valueB)) return -1;

    // Apply the descendingComparator logic
    const comparison = descendingComparator(a, b, orderBy, isDate);

    return order === 'desc' ? comparison : -comparison;
  };
}

export function getComparator<Key extends keyof any>(
  order: 'asc' | 'desc',
  orderBy: Key,
  isDate: boolean = false,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return createModifiedComparator(order, orderBy, isDate);
}
