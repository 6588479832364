// material ui
import { Card, Table, TableBody, TableContainer, Stack } from '@mui/material';
// react
import { useCallback, useMemo, useState, useEffect } from 'react';
// locales
import { useLocales } from 'src/locales';
// component
import { TableHead, TableNoData, getComparator, useTable } from 'src/components/table';
import { IOrderStats } from 'src/types';
import { fConvertDateFormat } from 'src/utils/format-date';
import CustomTableRow from './custom-table-row';

export default function CustomerOrderedTable({ data }: { data: IOrderStats[] }) {
  const { t } = useLocales();
  const [tableData, setTableData] = useState<any[]>([]);
  const [totalData, setTotalData] = useState<IOrderStats>({
    orderId: '',
    deliveryDate: null,
    recipient: '',
    bottles: 0,
    courier: '',
  });

  const TABLE_COLUMNS = useMemo(
    () => [
      { field: 'orderId', title: t('order_code'), width: 100, align: 'center' as const },
      { field: 'deliveryDate', title: t('data_spedizione'), width: 200, align: 'center' as const },
      { field: 'recipient', title: t('destinatario'), width: 50, align: 'center' as const },
      { field: 'bottles', title: t('bottiglie'), width: 50, align: 'center' as const },
      { field: 'courier', title: t('corriere'), width: 50, align: 'center' as const },
    ],
    [t],
  );

  const table = useTable({
    defaultOrderBy: 'orderId',
    defaultOrder: 'desc',
  });

  const notFound = !data.length;

  const tableDataModifier = useCallback(
    (rows: IOrderStats[]) =>
      rows?.map((row) => ({
        ...row,
        deliveryDate: row?.deliveryDate ? fConvertDateFormat(row?.deliveryDate || '') : '---',
      })) || [],
    [],
  );

  const calculateTotalBottles = useCallback(
    (rows: IOrderStats[]) => rows.reduce((total, row) => total + (row.bottles || 0), 0),
    [],
  );

  useEffect(() => {
    if (data) {
      setTableData(tableDataModifier(data));

      // Set total bottles
      const totalBottles = calculateTotalBottles(data);
      setTotalData({ ...totalData, bottles: totalBottles });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator:
      table.orderBy === 'orderId'
        ? getOrderIdComparator(table.order)
        : getComparator(table.order, table.orderBy, table.orderBy === 'deliveryDate'),
  });

  return (
    <Stack spacing={1}>
      <Card sx={{ marginBottom: 2 }}>
        <TableContainer sx={{ maxHeight: '70vh', overflow: 'auto' }}>
          <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
            <TableHead
              order={table.order}
              orderBy={table.orderBy}
              headLabel={TABLE_COLUMNS}
              onSort={table.onSort}
            />
            <TableBody>
              {dataFiltered.map((row) => (
                <CustomTableRow key={row.orderId} row={row} headLabel={TABLE_COLUMNS} />
              ))}

              {dataFiltered.length > 0 && (
                <CustomTableRow
                  key={44}
                  row={{ ...totalData, orderId: t('total') }}
                  headLabel={TABLE_COLUMNS}
                  isTotalRow
                />
              )}

              <TableNoData notFound={notFound} />
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </Stack>
  );
}
// apply filter
function applyFilter({
  inputData,
  comparator,
}: {
  inputData: IOrderStats[];
  comparator: (a: any, b: any) => number;
}) {
  inputData.sort((a, b) => {
    const sortOrder = comparator(a, b);
    return sortOrder;
  });
  return inputData;
}

function getOrderIdComparator(order: 'asc' | 'desc') {
  return (a: IOrderStats, b: IOrderStats) => {
    const [prefixA, yearA] = a.orderId.split('-');
    const [prefixB, yearB] = b.orderId.split('-');

    // Extract numeric part from the orderId using a regular expression
    const numA = parseInt(prefixA.match(/\d+/)?.[0] || '0', 10);
    const numB = parseInt(prefixB.match(/\d+/)?.[0] || '0', 10);

    if (yearA !== yearB) {
      return order === 'asc' ? yearA.localeCompare(yearB) : yearB.localeCompare(yearA);
    }
    return order === 'asc' ? numA - numB : numB - numA;
  };
}
