import { useCallback } from 'react';
// @mui
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
// import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';

// import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
// import v from 'voca';
// hooks
// import Select, { SelectChangeEvent } from '@mui/material/Select';
// constants
import { FILE_TYPES } from 'src/constants/misc';
// components
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { useLocales } from 'src/locales';

// ----------------------------------------------------------------------

type Props<T> = {
  filters?: T;
  onFilters?: (name: string, value: string) => void;
  //
  loading: boolean;
  fileDownloadHandler?: (arg: string) => void;
  isFileDownloading?: boolean;
  importDialog?: any | null;
  importLabel?: string;
  allowPDFExport?: boolean;
  allowUtilityExport?: boolean;
  utilityExportFileType?: string;
  utilityExportLabel?: string;
};

export default function TableSearch<T extends { searchText?: string }>({
  filters,
  onFilters,
  //
  loading,
  fileDownloadHandler,
  isFileDownloading = false,
  importDialog,
  importLabel,
  allowPDFExport = false,
  allowUtilityExport = false,
  utilityExportFileType,
  utilityExportLabel,
}: Props<T>) {
  const { t } = useLocales();
  const popover = usePopover();

  const handleFilterSearchText = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onFilters?.('searchText', event.target.value);
    },
    [onFilters],
  );

  const renderFileDownloader = (
    <>
      {fileDownloadHandler && (
        <CustomPopover
          open={popover.open}
          onClose={popover.onClose}
          arrow="right-top"
          sx={{ minWidth: 200 }}
        >
          <MenuItem
            onClick={() => {
              if (fileDownloadHandler) {
                fileDownloadHandler(FILE_TYPES.XLSX);
              }
              popover.onClose();
            }}
          >
            <Iconify icon="solar:export-bold" />
            {`${t('export')} XLSX`}
          </MenuItem>
          <MenuItem
            onClick={() => {
              if (fileDownloadHandler) {
                fileDownloadHandler(FILE_TYPES.CSV);
              }
              popover.onClose();
            }}
          >
            <Iconify icon="solar:export-bold" />
            {`${t('export')} CSV`}
          </MenuItem>
          {allowPDFExport && (
            <MenuItem
              onClick={() => {
                if (fileDownloadHandler) {
                  fileDownloadHandler(FILE_TYPES.PDF);
                }
                popover.onClose();
              }}
            >
              <Iconify icon="solar:export-bold" />
              {`${t('export')} PDF`}
            </MenuItem>
          )}
          {importDialog && (
            <MenuItem
              onClick={() => {
                importDialog.onTrue();
              }}
            >
              <Iconify icon="solar:import-bold" />
              {importLabel}
            </MenuItem>
          )}
          {allowUtilityExport && utilityExportFileType && utilityExportLabel && (
            <MenuItem
              onClick={() => {
                if (fileDownloadHandler) {
                  fileDownloadHandler(utilityExportFileType);
                }
                popover.onClose();
              }}
            >
              <Iconify icon="solar:export-bold" />
              {utilityExportLabel}
            </MenuItem>
          )}
        </CustomPopover>
      )}
    </>
  );

  return (
    <>
      <Stack
        spacing={2}
        alignItems={{ xs: 'flex-end', md: 'flex-start' }}
        direction={{
          xs: 'column',
          md: 'row',
        }}
        sx={{
          pr: { xs: 2.5, md: 1 },
        }}
      >
        <Stack direction="row" alignItems="center" spacing={2} flexGrow={1} sx={{ width: 1 }}>
          {filters && (
            <TextField
              fullWidth
              value={filters?.searchText || ''}
              onChange={handleFilterSearchText}
              placeholder={t('search')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <>
                    {loading ? <Iconify icon="svg-spinners:8-dots-rotate" /> : null}
                    {/* {params.InputProps.endAdornment} */}
                  </>
                ),
              }}
            />
          )}

          {isFileDownloading && <Iconify icon="line-md:downloading-loop" />}

          {!isFileDownloading && fileDownloadHandler && (
            <IconButton onClick={popover.onOpen}>
              <Iconify icon="eva:more-vertical-fill" />
            </IconButton>
          )}
        </Stack>
      </Stack>

      {renderFileDownloader}
    </>
  );
}
