export const createYears = (skipEveryone = false, add12MonthsFilter = false) => {
  const startedYear = 2017;
  const endYear = new Date().getFullYear();
  const yearsArray: any[] = Array(endYear - startedYear + 1)
    .fill('value')
    .map((_, index) => `${startedYear + index}`);

  if (!skipEveryone) yearsArray.unshift('all');

  // Add last 12 months and previous 12 months filters to year
  if (add12MonthsFilter) {
    yearsArray.push('last_12_months', 'previous_12_months');
  }

  return yearsArray;
};
