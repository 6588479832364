// sections
import UnpaidClientOrdersView from 'src/sections/admin/unpaid-orders/view/unpaid-orders-dashboard-view';
import Head from 'src/components/head';
// hooks
import { useLocales } from 'src/locales';

export default function UnpaidOrdersDashboardPage() {
  const { t } = useLocales();
  return (
    <>
      <Head title={`${t('unpaid_orders')}`} />
      <UnpaidClientOrdersView />
    </>
  );
}
